import React from "react"
import CookieConsent from "react-cookie-consent"

const CookieInfo = () => (
  <CookieConsent
    buttonText="Ok"
    style={{ background: "white", color: "black" }}
    buttonStyle={{ backgroundColor: "#21c5c6" }}
  >
    Ta strona korzysta z ciasteczek aby świadczyć usługi na najwyższym poziomie. Dalsze korzystanie ze strony oznacza,
    że zgadzasz się na ich użycie.
    <a
      style={{ textDecoration: "none", opacity: 0.6, color: "black" }}
      href="https://skrypt-cookies.pl/czym-sa-ciasteczka"
    >
      Więcej informacji
    </a>
  </CookieConsent>
)

export default CookieInfo
